import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

// components
import SectionTitle from '@components/atoms/SectionTitle';
import AnchorNav from '@components/molecules/AnchorNav';
import CardHighlight from '@components/molecules/cards/CardHighlight';
import Cta from '@components/molecules/Cta';
import HeroPage from '@components/molecules/heros/HeroPage';
import ListTeam from '@components/organisms/ListTeam';
import Layout from '@src/layouts';

// assets
import imgGoal from '@assets/images/goal.svg';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-team';

const language = 'en';

// --------------------------------
// #region data
// --------------------------------

const footerRelatedLinks = [
	{
		title: 'Agency',
		url: '/en/agency/',
	},
	{ title: 'Work', url: '/en/projects/' },
];

// --------------------------------
// #endregion
// --------------------------------
const TeamPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				teamImages: allFile(
					filter: {
						relativePath: {
							in: [
								"team/felipe.jpg"
								"team/sylvain.jpg"
								"team/charlotte.jpg"
								"team/alexandre.jpg"
								"team/simeon.jpg"
								"team/samuel.jpg"
								"team/pierrick.jpg"
								"team/emilien.jpg"
								"team/fabien.jpg"
								"team/hugonew.jpg"
								"team/thomas.jpg"
								"team/nadine.jpg"
								"team/raphael.jpg"
								"team/audrey.jpg"
								"team/marion.jpg"
								"team/aurel.jpg"
								"team/dmytro.jpg"
							]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 228, quality: 90) {
									...GatsbyImageSharpFluid_withWebp_noBase64
								}
							}
						}
					}
				}
				card_culture: file(relativePath: { eq: "card_culture.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 680, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const teamMembers = [
		{
			name: 'Sylvain',
			role: 'Brand Designer',
			image: data.getImageAttributes('teamImages', 'team/sylvain.jpg'),
			link: '/todo',
		},
		{
			name: 'Pierrick',
			role: 'Client Partner',
			image: data.getImageAttributes('teamImages', 'team/pierrick.jpg'),
			link: '/todo',
		},
		{
			name: 'Hugo',
			role: 'Partner, Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/hugonew.jpg'),
			link: '/todo',
		},
		{
			name: 'Nadine',
			role: 'Designer',
			image: data.getImageAttributes('teamImages', 'team/nadine.jpg'),
			link: '/todo',
		},
		{
			name: 'Samuel',
			role: 'Partner, Business Developer',
			image: data.getImageAttributes('teamImages', 'team/samuel.jpg'),
			link: '/todo',
		},
		{
			name: 'Emilien',
			role: 'Visual Designer',
			image: data.getImageAttributes('teamImages', 'team/emilien.jpg'),
			link: '/todo',
		},
		{
			name: 'Fabien',
			role: 'Founder, Strategist',
			image: data.getImageAttributes('teamImages', 'team/fabien.jpg'),
			link: '/todo',
		},
		{
			name: 'Charlotte',
			role: 'Frontend Developer',
			image: data.getImageAttributes('teamImages', 'team/charlotte.jpg'),
			link: '/todo',
		},
		{
			name: 'Raphaël',
			role: 'Client Partner',
			image: data.getImageAttributes('teamImages', 'team/raphael.jpg'),
			link: '/todo',
		},
		{
			name: 'Siméon',
			role: 'Motion Designer',
			image: data.getImageAttributes('teamImages', 'team/simeon.jpg'),
			link: '/todo',
		},
		{
			name: 'Felipe',
			role: 'Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/felipe.jpg'),
			link: '/todo',
		},
		{
			name: 'Thomas',
			role: 'Partner, Design Lead',
			image: data.getImageAttributes('teamImages', 'team/thomas.jpg'),
			link: '/todo',
		},
		{
			name: 'Audrey',
			role: 'Client Partner',
			image: data.getImageAttributes('teamImages', 'team/audrey.jpg'),
			link: '/todo',
		},
		{
			name: 'Dmytro',
			role: 'Developer Apprentice',
			image: data.getImageAttributes('teamImages', 'team/dmytro.jpg'),
			link: '/todo',
		},
		{
			name: 'Aurel',
			role: 'Fullstack Developer',
			image: data.getImageAttributes('teamImages', 'team/aurel.jpg'),
			link: '/todo',
		},
		{
			name: 'Marion',
			role: 'Frontend Developer',
			image: data.getImageAttributes('teamImages', 'team/marion.jpg'),
			link: '/todo',
		},
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/equipe/', lang: 'fr' }]}
			title="Team - Superhuit"
		>
			<HeroPage
				image={imgGoal}
				imageX={47}
				imageY={44}
				title="The Team"
			/>

			<section
				className="grid text-content text-center"
				aria-label="About"
				data-animation-page
			>
				<h2 className="col-big">
					We are a bunch of doers with different outlooks – artisans
					of digital experience and&nbsp;esthetics.
				</h2>

				<AnchorNav
					className="col-big"
					anchors={[
						{
							title: 'Our team',
							href: '#team',
						},
						{
							title: 'Our Culture',
							href: '#culture',
						},
					]}
				/>
			</section>

			<section id="team" className="section-team" aria-label="Team">
				<SectionTitle title="Ils font notre succès" />

				<div className="grid section-content">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Team</p>
						<h2>Success stories in the plural</h2>
						<p>
							Our strength is rooted in the diversity of our
							personalities, in our passion and dedication.
							Because challenges give meaning to our lives, we are
							constantly challenging our own processes to keep
							pace with an ever-changing world.
						</p>

						<ListTeam
							teamMembers={teamMembers}
							className={['justify-center']}
						/>
					</div>

					<Cta
						text="…are you interested in joining us in this exciting adventure?"
						cta="Apply"
						url="/en/jobs/"
					/>
				</div>
			</section>

			<section id="culture" className="grid" aria-label="Culture">
				<CardHighlight
					className="col-big"
					uptitle="Superhuit organizational culture"
					text="Ideas come and go, people change, but culture remains."
					link={{
						title: 'Discover our culture',
						url: '/en/culture/',
					}}
					image={data.card_culture.childImageSharp}
				/>
			</section>
		</Layout>
	);
};

export default TeamPage;
